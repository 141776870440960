import { forkJoin, from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Service, ServiceLawyer, ServiceType } from 'src/app/shared/interfaces/common';
import Swal from 'sweetalert2';

import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BranchesLaw } from '../../../shared/interfaces/common';
import { AdminService } from '../../../shared/services/admin.service';
import { CommonService } from '../../../shared/services/common.service';
import { LawyerService } from '../../../shared/services/lawyer.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component( {
  selector: 'app-service-configuration',
  templateUrl: './service-configuration.component.html',
  styleUrls: [ './service-configuration.component.css' ],
} )
export class ServiceConfigurationComponent implements OnInit {
  page;
  dataPagination;
  form: FormGroup;
  isSubmitted = false;
  services: Service[] = [];
  service: Service;
  lawyerData: any;

  lawyerServs: ServiceLawyer[] = [];
  lawyerServ: ServiceLawyer;
  modal: any;
  isEdit = false;
  branchs: BranchesLaw[] = [];
  typeServices: ServiceType[] = [];
  search = {
    service_id:"",
    phases:"",
    start_price:"",
    end_price:""
  };

  branchID: number;
  serviceTypeID: number;
  fase = 0;

  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private common: CommonService,
    private modalService: NgbModal,
    private lawyerService: LawyerService,
	private profile: ProfileService,
	private auth: AuthService,
    titlePageService: TitlePageService
  ) {
    this.page = 1;
    titlePageService.titlePageObservableData = { name: 'Configurar Servicios'};
    this.createForm();
	this.profile.getLawyer(this.auth.getCurrentUserEntityId()).subscribe( ({ data }) => {
	// data.branch_laws = this.convertBranchLawsArrToArrIds(data.branch_laws);
		this.lawyerData = data;
		forkJoin( [ this.adminService.listServiceSetting(),  this.adminService.listBranchs(), this.common.typeService() ] )
		  .subscribe( ( [ servicesResponse, branchsResponse, typesResponse ] ) => {
			this.services = [ ...servicesResponse.data ].filter( service => this.lawyerData.branch_laws.map(b => b.id).includes( service.branch_law_id ) );
			this.branchs = [ ...branchsResponse.data ].filter( branch => this.lawyerData.branch_laws.map(b => b.id).includes( branch.id ) );
			this.typeServices = [ ...typesResponse.data ];
		  } );
	})
	
	
    this.loadData(this.page);
  }

  get f(): any { return this.form.controls; }
  get phases(): FormArray { return this.form.get( 'phases' ) as FormArray; }

  ngOnInit(): void {
    // this.addPhase();
  }

  addPhase( fase?: any ): void {
    this.fase++;
    const phases = this.formBuilder.group( {
      phase: [ fase ? fase.phase : this.fase, [ Validators.required ] ],
      description: [ fase ? fase.description : '', [ Validators.required ] ],
      percent: [ fase ? fase.percent : '', [ Validators.required ] ],
    } );
    this.phases.push( phases );
    this.form.controls.phases_number.patchValue( this.phases.length );
  }

  removePhase(): void {
    const index = this.phases.length;
    this.phases.removeAt( index - 1 );
    this.form.controls.phases_number.patchValue( this.phases.length );
  }

  add( serviceModal: any ): void {
    this.form.reset();
    this.createForm();
    this.isEdit = false;
    this.openModal( serviceModal );
  }

  edit( serviceModal: any, service: ServiceLawyer ): void {
    this.isEdit = true;
    this.lawyerServ = { ...service };
    this.onChange( this.lawyerServ.service_id );
    this.createFormEdit();
    this.lawyerServ.phases.forEach( fase => this.addPhase( fase ) );
    this.openModal( serviceModal );
  }

  delete( id: number ): void {
    Swal.fire( {
      title: 'Aviso',
      text: '¿Seguro desea eliminar esta servicio?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, eliminar!'
    } ).then( ( result ) => {
      if ( result.isConfirmed ) { this.deleteService( id ); }
    } );
  }

  onSubmit(): void {
    const percentage: number[] = [];
    this.isSubmitted = true;

    // extracción de la propiedad percentage del array de phases
    from( this.form.value.phases )
      .pipe( pluck( 'percent' ) )
      .subscribe( ( val: number ) => percentage.push( val ) );

    const total = percentage.reduce( ( acc, curr ) => acc + curr );
    if ( this.form.valid ) {
      if ( total !== 100 ) {
        Swal.fire( { text: 'La suma de los porcentajes debe ser 100', icon: 'warning' } );
        return;
      }
      if ( this.isEdit ) {
        this.updateService();
        return;
      }
      this.addService();
    } else {
    }
  }

  onChange( id: number ): void {
    const service = this.services.find( item => item.id == id );
    this.branchID = service.branch_law_id;
    this.serviceTypeID = service.type_service_id;
  }

  private loadData(page): void {

    const params = new URLSearchParams(this.search);
    const queryString = params.toString();

    this.lawyerService.services(page, queryString).subscribe( response => {
      this.lawyerServs = [ ...response.data ];
      this.dataPagination = response.meta.page;
    } );
  }

  loadPage(page) {
    this.page = page;
    this.loadData(this.page);
  }

  private openModal( serviceModal: any ): void {
    this.modal = this.modalService.open( serviceModal, { backdrop: 'static' } );
  }

  private addService(): void {
    this.lawyerService.addService( this.form.value ).subscribe( () => {
      this.modal.dismiss();
      this.loadData(this.page);
      Swal.fire( { text: 'Servicio creado', icon: 'success' } );
    } );
  }

  private updateService(): void {
    this.lawyerService.updateService( this.form.value, this.lawyerServ.id ).subscribe( () => {
      this.modal.dismiss();
      this.loadData(this.page);
      Swal.fire( { text: 'Servicio actualizado', icon: 'success' } );
    } );
  }

  private deleteService( id: number ): void {
    this.lawyerService.deleteService( id ).subscribe( () => {
      this.modal.dismiss();
      this.loadData(this.page);
      Swal.fire( { text: 'Servicio eliminado', icon: 'success' } );
    } );
  }

  private createForm(): void {
    this.form = this.formBuilder.group( {
      service_id: [ '', [ Validators.required ] ],
      phases_number: [  '', [ Validators.required ] ],
      price: [  '', [ Validators.required ] ],
      price_consulting: [  '', [ Validators.required ] ],
/*       priceLow: [  '', [ Validators.required ] ],
      priceHigh: [  '', [ Validators.required ] ], */
      priceLow: [  '' ],
      priceHigh: [  '' ],
      phases: this.formBuilder.array( [] )
    } );
  }

  private createFormEdit(): void {
    this.form = this.formBuilder.group( {
      service_id: [ this.lawyerServ ? this.lawyerServ.service_id : '', [ Validators.required ] ],
      price_consulting: [  this.lawyerServ ? this.lawyerServ.price_consulting : '', [ Validators.required ] ],
      phases_number: [ this.lawyerServ ? this.lawyerServ.phases_number : '', [ Validators.required ] ],
      price: [ this.lawyerServ ? this.lawyerServ.price : '', [ Validators.required ] ],
      phases: this.formBuilder.array( [] )
    } );
  }

  onKey(event: any) { // with type info
    if ( event.target.value.length === 0) {
      this.loadPage(this.page);
    }
  }

  clear(){
    this.search = {
      service_id:"",
      phases:"",
      start_price:"",
      end_price:""
    };

    this.loadPage(1)
  }
}
