<div class="content-body d-flex">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3>Solicitudes de asesorías</h3>
        <div>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngIf="items.length; else elseTemplate">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Nombre de trámite o proceso</th>
                  <th scope="col">Nombre de solicitante</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of items; let i=index">
                  <td class="text-capitalize">{{item.service_name}}</td>
                  <td class="text-capitalize">{{item.user_name }}</td>
                  <td>
                    <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
                      (click)="openDetailModal(modalDetail, item)" placement="top" ngbTooltip="Detalle">
                      <mat-icon aria-hidden="false" aria-label="Example home icon" style="color: #212F54;">
                        list
                      </mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <div class="alert alert-warning" role="alert">
            <strong>No hay data disponible</strong>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #modalDetail let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Detalle de solicitud de asesoría</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeDetailModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>Fecha:</strong> {{item.created_at}}</p>
    <p><strong>Fecha programada:</strong> {{item?.available_date}}</p>
    <p><strong>Nombre del trámite o proceso:</strong> {{item.service.name}}</p>
    <p><strong>Tipo de Asesoría:</strong> {{item.consulting_type}}</p>
    <p><strong>Nombre del solicitante:</strong> {{ item.user.full_name}}</p>
    <p><strong>Descripción:</strong> {{item.description}}</p>
    <p *ngIf="moreInfo"><strong>Información extra:</strong> {{moreInfo}}</p>

    <div class="form-group no-conflict bg-question p-3 text-center rounded">
      <label class="mr-1 h5 d-block">¿ Existen conflictos de intereses en esta solicitud ?</label>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="0" checked
          [(ngModel)]="noConflict" [ngModelOptions]="{standalone: true}">
        <label class="form-check-label" for="inlineRadio1">Si</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="1"
          [(ngModel)]="noConflict" [ngModelOptions]="{standalone: true}">
        <label class="form-check-label" for="inlineRadio2">No</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" [disabled]="moreInfo" class="btn btn-primary mr-2"
      (click)="openMoreInfoModal(moreInfoModal, item)">
      Solicitar Información
    </button>
    <button type="button" class="btn btn-primary ml-2" (click)="registerDecision(item.id, 1)"
      [disabled]="!noConflict">Aceptar</button>
    <button type="submit" class="btn btn-outline-danger mr-2" (click)="registerDecision(item.id, 0)">Rechazar</button>
  </div>
</ng-template>


<ng-template #moreInfoModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Solicitud de información adicional</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeMoreInfoModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form class="form-valide" [formGroup]="miForm" (ngSubmit)="onSubmitInfo(item)" novalidate>
    <div class="modal-body">
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="mb-1"><strong>Mas información</strong></label>
            <textarea class="form-control" name="" id="" rows="3" formControlName="body"></textarea>
            <div *ngIf="submitted && g.body?.errors" class="text-danger fs-13 mt-1">
              <div *ngIf="g.body.errors.required">
                Campo requerido.
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-danger mr-2" (click)="closeMoreInfoModal()">Cancelar</button>
      <button type="submit" class="btn btn-primary ml-2">Aceptar</button>
    </div>
  </form>
</ng-template>