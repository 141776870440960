import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { PaymentsService } from '../../services/payments.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

declare var paypal;

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit {
  @Input() data: any;
  @ViewChild('paypal', { static: true }) paypalElement : ElementRef;
  @Output() payEvent = new EventEmitter<boolean>();

  paymentM=[];
  pagesView=0;
  paymentSelected:any;
  message=false;
  messagePay=false;
  messageText='';
  cardFormGroup: FormGroup;
  submitted = false;
  payPaypal=false;
  transferFormGroup: FormGroup;
  submittedTransfer = false;
  transferFormData: FormData;
  refTransfer='';
  fileName = 'Seleccione archivo';
  viewTransfer: boolean = true;
  capFile: File;
  private _transferFile = '';
  constructor(
    private fb: FormBuilder,
    private paymentsService: PaymentsService,
    private router: Router,
  ) {
    this.createTransfer();

  }

  get f(): any { return this.cardFormGroup.controls; }
  get t(): any { return this.transferFormGroup.controls; }

  ngOnInit(): void {
    console.log(this.data)
    this.viewTransfer = (this.data.type === 'Subscription') ? false : true;
    this.loadData();
    this.paypal();
  }

  createTransfer(): void {
    this.transferFormGroup = this.fb.group({
      file_url: ['', Validators.required],
      amount: ['', Validators.required],
      //reference_number: ['', Validators.required, Validators.pattern('[0-9]*')],
    });
  }

  onTransferChange( e ): void {


    const reader = new FileReader();
    if ( e.target.files && e.target.files.length ) {
      const [ file ] = e.target.files;
      this.fileName = file.name;
      this.capFile = file ;
      reader.readAsDataURL( file );
      reader.onload = () => {
        this._transferFile = reader.result as string;
      };
    }
  }

  private loadData(): void {
    this.paymentsService.getPaymentMethods().subscribe( response => {
      this.paymentM = [ ...response.data ];

      if ( !this.viewTransfer) {
        this.paymentM = this.paymentM.filter(item => item.name !== 'Transferencia');
      }

    } );
  }

  next(){
    console.log(this.paymentSelected);
    if(this.paymentSelected === undefined) {
      this.message=true;
    } else {
      this.message=false;

      switch (this.paymentSelected.id) {
        case 1:
          this.createForm();
          this.pagesView=this.paymentSelected.id;
          this.payPaypal=false;
          break;
        case 2:
          this.createTransfer();
          this.pagesView=this.paymentSelected.id;
          this.payPaypal=false;
          break;
        case 3:
          this.payPaypal=true;
          break;
      }

    }

  }

  paypal(){
    paypal
    .Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description : this.data.description,
              amount: {
                currency_code: "USD",
                value: this.data.phase_info.pending,
              }
            }
          ]
        })
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        //console.log(order);
        this.registerPay(order.id, this.data.phase_info.pending);
      },
      onError: err => {
        console.log(err);
      }
    })
    .render(this.paypalElement.nativeElement);


  }

  createForm(): void {
    this.cardFormGroup = this.fb.group ({
      name: ['',  Validators.required],
      last_name: ['',  Validators.required],
      ccnumber: ['',  [Validators.required, Validators.pattern('[0-9]{16}') ]],
      cvv: ['',  [Validators.required, Validators.pattern('[0-9]{3}') ] ],
      ccexp: ['',  [Validators.required, Validators.pattern('([0-9]{2})\/([0-9]{2})') ] ],
      amount: ['',  Validators.required],
    });
  }

  registerTransfer(){
    this.submittedTransfer = true;

    if (this.transferFormGroup.valid && this.refTransfer!=='') {

      if(this.transferFormGroup.value.amount > this.data.phase_info.pending)
        {
          Swal.fire({
            icon: 'error',
            text: 'Monto no permitido.',
          })
  
          return;
        }

      const transferFormGroup = document.getElementById('transferFormGroup') as HTMLFormElement;
      this.transferFormData = new FormData(transferFormGroup);
      this.transferFormData.append('order_id', this.data.order.toString() );
      this.transferFormData.append('status', 'Pendiente');
      this.transferFormData.append('payment_method_id', this.paymentSelected.id.toString() );
      this.transferFormData.append('amount', this.transferFormGroup.value.amount );
      this.transferFormData.append('reference_number', this.refTransfer.toString() );
      this.transferFormData.append('phase', this.data.phase_info.phase );
      this.transferFormData.append('reference_file', this.capFile, this.capFile.name);


      this.paymentsService.registerPay(this.transferFormData).subscribe(
        res =>{
            this.messagePay= true;
            this.messageText = "¡Su pago ha sido procesado exitosamente!"
            // si entro aqui es porque se registro del tramite
			this.payEvent.emit(true);
        },
        error=>{
           console.log(error);
        }
      );

    }
  }

  testEvent() {
	this.payEvent.emit(true);
  }

  registerPayment(){
    this.submitted = true;
    //console.log(this.cardFormGroup);
    //console.log(this.cardFormGroup.valid);


    if (this.cardFormGroup.valid) {
      //this.cardFormGroup.value.amount = this.data.amount;

      if(this.cardFormGroup.value.amount > this.data.phase_info.pending)
      {
        Swal.fire({
          icon: 'error',
          text: 'Monto no permitido.',
        })

        return;
      }
      //console.log(this.cardFormGroup.value);

      // pago al banco
      this.paymentsService.credicorp(this.cardFormGroup.value).subscribe(
        res =>{
          if ( res.status !== 'Error') {
                      /* si el pago es exitoso guardarlo en la base de datos */
          //console.log(res);
          this.registerPay(res.reference_code, this.cardFormGroup.value.amount);

          } else {
            Swal.fire({
              icon: 'error',
              text: res.message,
            })
          }



        },
        error=>{
           console.log(error.error.message);
        }
      );




    }



  }


  registerPay(ref, amount){


    let data = {
      "reference_number":ref,
      "order_id": this.data.order,
      "payment_method_id": this.paymentSelected.id,
      "amount": amount,
      "phase":this.data.phase_info.phase,
      "status": "Aprobado",
    }

    console.log(data);


    if ( !this.viewTransfer)  {

      this.paymentsService.registerPayProducts(data).subscribe(
        res =>{
            console.log(res);

            this.messagePay= true;
            this.messageText = "¡Su pago ha sido procesado exitosamente!";
            this.router.navigateByUrl('lawyer/index');
            // si entro aqui es porque se registro del tramite
			this.payEvent.emit(true);
        },
        error=>{
           console.log(error);
        }
      );
    } else {
      this.paymentsService.registerPay(data).subscribe(
        res =>{
            console.log(res);
            this.messagePay= true;
            this.messageText = "Registro exitoso, en poco tiempo verificaremos su pago!"
            // si entro aqui es porque se registro del tramite
        },
        error=>{
           console.log(error);
        }
      );
    }



  }

}
