import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/shared/services/admin.service';
import { LawyerService } from 'src/app/shared/services/lawyer.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-approved-quotes',
  templateUrl: './approved-quotes.component.html',
  styleUrls: ['./approved-quotes.component.css']
})
export class ApprovedQuotesComponent implements OnInit {

  modal: any;
  list = [];
  params = '';
  info_object : any;
  currentItem : any;
  page; dataPagination; 
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null,
    service_name: "",
    client_name: "",
    type: ""
  };
  assetUrl = environment.assetsUrl;
  hoveredDate: NgbDate | null = null;
  types = [];
  typesService = [];
  constructor(
    private modalService: NgbModal,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private lawyerService: LawyerService,
    private adminService: AdminService
  ) { 
    this.page = 1;
  }

  ngOnInit(): void {
    this.loadData();
    this.loadFilters();
  }

  downloadPDF() {
    this.lawyerService.getApprovedQuotes(null, this.params, true).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Cotizaciones Aprobadas.pdf";
        link.click();
    });
  }

  openModal(detailModal: any, item) {
    this.lawyerService.getApprovedQuote(item.id).subscribe(response => {
      this.currentItem = response.data;
      this.modal = this.modalService.open( detailModal, { size: 'xl' } );    
    })
  }

  loadData(page = 1) {
    this.lawyerService.getApprovedQuotes(page, this.params).subscribe(
      response => {
        this.list = response.data;
        this.info_object = response.info_object;
        this.dataPagination = response.meta.page;
    });
  }

  loadFilters(page = 1) {
    this.lawyerService.listService().subscribe(
      response => {
        this.types = response.data;
    });
    this.adminService.listService().subscribe(
      response => {
        this.typesService = response.data;
      }
    )
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter() {
    this.params = '';
    this.searchFilter = {
      dateStart: null,
      dateEnd: null,
      service_name: null,
      client_name: null,
      type: ""
    }
    this.toDate = null;
    this.fromDate = null;
    this.loadData();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  search(){
    this.searchFilter.dateStart = this.formatter.format( this.fromDate );
    this.searchFilter.dateEnd = this.formatter.format( this.toDate );
    this.params = '';
    
    if (this.searchFilter.dateStart!=='') {
      this.params = this.params+'&start_date='+this.searchFilter.dateStart;
    }
    
    if (this.searchFilter.dateEnd!=='') {
      this.params = this.params+'&end_date='+this.searchFilter.dateEnd;
    }

    if (this.searchFilter.client_name) {
      this.params += '&client_name=' + this.searchFilter.client_name;
    }

    if (this.searchFilter.service_name) {
      this.params += '&service_name=' + this.searchFilter.service_name;
    }

    if (this.searchFilter.type !== '') {
      this.params += '&type_service_id=' + this.searchFilter.type;
    }

    if (this.params !== '') {
      this.loadData();
    }
  }

  messageError(){
    Swal.fire({
      title: "Archivo no encontrado" ,
      text: "El archivo adjunto al pagado no ha sido encontrado" ,
      icon: "error",
      timer: 3000,
    })

  }

  approveOrder(order_id){
    Swal.fire( {
      title: 'Aviso',
      text: '¿Seguro desea aprobar este pago?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, aprobar!',
      cancelButtonText : 'Cancelar',
    } ).then( ( result ) => {
      if ( result.isConfirmed ) {
         this.adminService.updatePayments(order_id , {status : 'Aprobado'}).subscribe(rsp =>{
          Swal.fire({title: "Pago Confirmado",  icon: "success"})
          this.modal.close()
         })
        }
    });
  }

  cancelOrder(order_id){
    Swal.fire( {
      title: 'Aviso',
      text: '¿Seguro desea Rechazar este pago?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si!',
      cancelButtonText : 'Cancelar',
    } ).then( ( result ) => {
      if ( result.isConfirmed ) {

         this.adminService.updatePayments(order_id , {status : 'Denegado'}).subscribe(rsp =>{
          Swal.fire({title: "Pago Rechazado",  icon: "success"})
          this.modal.close()
         })
        }
    });
  }
}
