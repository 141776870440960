import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { NaturalClient, LegalClient } from '../classes/client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(private http: HttpService) {}

  getNaturalClient(id: number): Observable<any> {
    return this.http.get(`persons/${id}?includes[]=user&includes[]=township`);
  }

  getLegalClient(id: number): Observable<any> {
    return this.http.get(`companies/${id}?includes[]=user&includes[]=township`);
  }
  createNaturalClient(client: NaturalClient): Observable<NaturalClient> {
    return this.http.post('', client);
  }
  createLegalClient(client: LegalClient): Observable<LegalClient> {
    return this.http.post(`companies`, client);
  }
  getBranchLaw(): Observable<any> {
    return this.http.get(`branch-laws`);
  }

   getLawForBrach(branch_law_id: number, type_service_id: number, page: number): Observable<any> {
    return this.http.get(`lawyers?includes[]=user&branch_law_id=${branch_law_id}&type_service_id=${type_service_id}&page=${page}`);
  }

  getBrachForService(type_service_id: number, page: number): Observable<any> {
    return this.http.get(`branch-laws?includes[]=services.lawyer_Service.lawyerBranchs&type_service_id=${type_service_id}&page=${page}`);
  }

   getServForBrach( type_service_id: number, branch_law_id: number): Observable<any> {
    return this.http.get(`services?type_service_id=${type_service_id}&branch_law_id=${branch_law_id}`);
  }

   getRagePrice( service_id: number, type: 'Asesoria' | 'Cotización' = 'Cotización'): Observable<any> {
    return this.http.get(`lawyer-services/rank?service_id=${service_id}&type=${type}`);
  }
  getLawyerForPrice(  page: number, price_start: number, price_end: number, service_id: number, typeServ: 'Asesoria' | 'Cotización' = 'Cotización'): Observable<any> {
    return this.http.get(`lawyers?includes[]=user&page=${page}&price_start=${price_start}0&price_end=${price_end}&service_id=${service_id}&type_service=${typeServ}`);
  }

  regTram1(params: any): Observable<LegalClient> {
    return this.http.post(`formalities`, params);
  }

  regPayment(params: any): Observable<any> {
    return this.http.post('order-payments', params);
  }

  getTdc(user_id: number): Observable<any> {
    return this.http.get(`tdc?user_id=${user_id}`);
  }

  tdc(params: any): Observable<any> {
    return this.http.post('tdc', params);
  }

  cancelOrder(id, params: any): Observable<any> {
    return this.http.put(`orders/${id}`, params);
  }


  getProfileLawyer(id: number): Observable<any> {
    return this.http.get(`lawyers/${id}?includes[]=user&includes[]=studyLevels&includes[]=comments`);
  }

  geMonitoringService(order_status_type: string, page:number): Observable<any> {
    return this.http.get(`monitoring-service?includes[]=lawyer.user&includes[]=status&includes[]=orderLawyers&includes[]=service&order_status=${order_status_type}&page=${page}`);
  }

  getDocumentsForService(order_id: string, page:number): Observable<any> {
    return this.http.get(`order-documents?/order_id=${order_id}&includes[]=user&page=${page}`);
  }

  loadDocuments(documents:any): Observable<any>{
    // console.log(documents);
  return this.http.post(`order-documents`, documents);
}

  getDetailMonitoringService(id: number): Observable<any> {
    return this.http.get(`monitoring-service/${id}?includes[]=lawyer.user&includes[]=orderLawyers&includes[]=orderPayments&includes[]=status&includes[]=service&includes[]=orderDocuments&includes[]=lawyerService&includes[]=orderPayments`);
  }

  getMyQuote(approved_by_lawler: number, page:number){
    return this.http.get(`myquote?includes[]=service&approved_by_lawler=${approved_by_lawler}&page=${page}`);
  }

  registerTransferQuote(id,data){
    return this.http.post(`myquote/${id}`,data);
  }
  //  payment

  getNextPayments(page = 1): Observable<any> {
    return this.http.get(`orders/next-payments?includes[]=lawyerService&includes[]=service&page=${page}`);
  }

  getNextPayment(id): Observable<any> {
    return this.http.get(`orders/next-payment/${id}?includes[]=lawyerService&includes[]=service`);
  }

  getUpcomingPayment(id, pdf = false) {
    if (pdf == true) {
      return this.http.get( `orders/next-payment/${id}?includes[]=service&includes[]=lawyerService&is_pdf_report=true`, {responseType: 'blob' as 'json'} );
    } else {
      return this.http.get( `orders/next-payment/${id}?includes[]=service&includes[]=lawyerService` );
    }
  }

  getPayments(page = 1, params = '', pdf = false) {
    if (pdf == true) {
    return this.http.get( `order-payments?includes[]=order&includes[]=paymentMethod&is_pdf_report=true${params}`, {responseType: 'blob' as 'json'}  );
    } else {
      return this.http.get( `order-payments?includes[]=order&includes[]=paymentMethod&page=${page}${params}` );
    }
  }


  getPayment(id, pdf = false) {
    if (pdf == true) {
      return this.http.get( `order-payments/${id}?includes[]=paymentMethod&is_pdf_report=true`, {responseType: 'blob' as 'json'} );
    } else {
      return this.http.get( `order-payments/${id}?includes[]=paymentMethod` );
    }
  }
}
